.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  color: white;
  text-align: center;
  max-width: 1600px;
  margin: 0 auto;
}
.grid > * {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 8px 1px -6px #ff0000;
  padding: 5px 5px;
}

@media screen and (max-width: 768px) {
  .grid {
    font-size: 0.5rem;
    padding: 0 10px;
  }
}

.tableTitle {
  font-size: 3vw;
  text-transform: uppercase;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .tableTitle {
    font-size: 5vw;
  }
}

.span-col-2 {
  grid-column: span 2 / auto;
}
.span-col-4 {
  grid-column: span 4 / auto;
}
