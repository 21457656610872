*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Encode Sans Expanded', sans-serif;
}


body{
	background-color: #444;
}